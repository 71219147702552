var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-breadcrumbs',{attrs:{"items":[
      {
        text: _vm.$t('locations.title'),
        disabled: true,
      },
    ]}}),(_vm.error)?_c('app-error',{attrs:{"error":_vm.error}}):_vm._e(),_c('h1',[_vm._v(_vm._s(_vm.$t("locations.title")))]),_c('v-divider',{staticClass:"mb-2"}),_c('v-card',[_c('v-card-title',[_c('location-filter',{model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}}),_c('v-spacer'),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"100px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g({staticClass:"shrink",attrs:{"prepend-icon":"event","readonly":""},model:{value:(_vm._formattedFrom),callback:function ($$v) {_vm._formattedFrom=$$v},expression:"_formattedFrom"}},on))]}}]),model:{value:(_vm.fromMenu),callback:function ($$v) {_vm.fromMenu=$$v},expression:"fromMenu"}},[_c('v-date-picker',{attrs:{"scrollable":""},on:{"input":function($event){_vm.fromMenu = false}},model:{value:(_vm.from),callback:function ($$v) {_vm.from=$$v},expression:"from"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g({staticClass:"shrink",attrs:{"prepend-icon":"event","readonly":""},model:{value:(_vm._formattedTo),callback:function ($$v) {_vm._formattedTo=$$v},expression:"_formattedTo"}},on))]}}]),model:{value:(_vm.toMenu),callback:function ($$v) {_vm.toMenu=$$v},expression:"toMenu"}},[_c('v-date-picker',{attrs:{"scrollable":""},on:{"input":function($event){_vm.toMenu = false}},model:{value:(_vm.to),callback:function ($$v) {_vm.to=$$v},expression:"to"}})],1),_c('v-btn',{staticClass:"ml-3",attrs:{"loading":_vm.loading,"fab":"","small":""},on:{"click":_vm.download}},[_c('v-icon',[_vm._v("download")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{
      itemsPerPageOptions: [5, 10, 15, 25, 50, 100, 250, 500, 1000],
    },"headers":_vm.headers,"items":_vm.locations,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalCount,"fixed-header":"","item-key":"uid","multi-sort":""},on:{"update:options":function($event){_vm.options=$event},"click:row":function($event){return _vm.$router.push({ name: 'locations/details', params: { uid: $event.uid } })}},scopedSlots:_vm._u([{key:"item.subscription.status",fn:function({ item }){return [(item.subscription)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("subscriptions.status." + item.subscription.status))+" ")]):_vm._e()]}},{key:"item.subscription.standardTopics",fn:function({ item }){return [(item.subscription && item.subscription.standardTopics)?_c('ul',_vm._l((item.subscription.standardTopics),function(topic){return _c('li',{key:topic.uid},[_vm._v(" "+_vm._s(topic.name)+" ")])}),0):_vm._e()]}},{key:"item.subscription.premiumTopics",fn:function({ item }){return [(item.subscription && item.subscription.premiumTopics)?_c('ul',_vm._l((item.subscription.premiumTopics),function(topic){return _c('li',{key:topic.uid},[_vm._v(" "+_vm._s(topic.name)+" ")])}),0):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }