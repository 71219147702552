<template>
  <v-container>
    <v-breadcrumbs
      :items="[
        {
          text: $t('locations.title'),
          disabled: true,
        },
      ]"
    />
    <app-error v-if="error" :error="error" />
    <h1>{{ $t("locations.title") }}</h1>
    <v-divider class="mb-2" />

    <v-card>
      <v-card-title>
        <location-filter v-model="filters" />
        <v-spacer></v-spacer>

        <v-menu
          v-model="fromMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="100px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="_formattedFrom"
              prepend-icon="event"
              readonly
              v-on="on"
              class="shrink"
            ></v-text-field>
          </template>
          <v-date-picker v-model="from" @input="fromMenu = false" scrollable />
        </v-menu>

        <v-menu
          v-model="toMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="_formattedTo"
              prepend-icon="event"
              readonly
              v-on="on"
              class="shrink"
            ></v-text-field>
          </template>
          <v-date-picker v-model="to" @input="toMenu = false" scrollable />
        </v-menu>

        <v-btn @click="download" :loading="loading" fab small class="ml-3">
          <v-icon>download</v-icon>
        </v-btn>
      </v-card-title>
    </v-card>
    <v-data-table
      :footer-props="{
        itemsPerPageOptions: [5, 10, 15, 25, 50, 100, 250, 500, 1000],
      }"
      :headers="headers"
      :items="locations"
      :loading="loading"
      :options.sync="options"
      :server-items-length="totalCount"
      class="elevation-1"
      fixed-header
      item-key="uid"
      multi-sort
      @click:row="
        $router.push({ name: 'locations/details', params: { uid: $event.uid } })
      "
    >
      <template v-slot:item.subscription.status="{ item }">
        <span v-if="item.subscription">
          {{ $t("subscriptions.status." + item.subscription.status) }}
        </span>
      </template>
      <template v-slot:item.subscription.standardTopics="{ item }">
        <ul v-if="item.subscription && item.subscription.standardTopics">
          <li
            v-for="topic in item.subscription.standardTopics"
            :key="topic.uid"
          >
            {{ topic.name }}
          </li>
        </ul>
      </template>
      <template v-slot:item.subscription.premiumTopics="{ item }">
        <ul v-if="item.subscription && item.subscription.premiumTopics">
          <li v-for="topic in item.subscription.premiumTopics" :key="topic.uid">
            {{ topic.name }}
          </li>
        </ul>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import LocationFilter from "@/components/locations/LocationFilter";

export default {
  data() {
    return {
      locations: [],
      error: null,
      loading: false,
      totalCount: 0,
      filters: this.getFilters(),
      options: {},
      headers: [
        {
          text: this.$t("locations.status"),
          value: "subscription.status",
          sortable: false,
        },
        { text: this.$t("locations.title"), value: "title" },
        { text: this.$t("locations.visibility"), value: "status" },
        {
          text: this.$t("locations.standardTopics"),
          value: "subscription.standardTopics",
          sortable: false,
        },
        {
          text: this.$t("locations.premiumTopics"),
          value: "subscription.premiumTopics",
          sortable: false,
        },
      ],
      from: null,
      to: null,
      fromMenu: false,
      toMenu: false,
    };
  },
  watch: {
    options: {
      handler() {
        this.fetchLocations();
      },
      deep: true,
    },
    filters: {
      handler() {
        this.options.page = 1;
        this.storeFilters();
        this.fetchLocations();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchLocations();
  },
  methods: {
    fetchLocations() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.error = null;

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      var order = [];
      if (sortBy) {
        for (let i in sortBy) {
          order.push(sortBy[i] + ":" + (sortDesc[i] ? "desc" : "asc"));
        }
      }

      let params = {
        size: itemsPerPage,
        page: page,
        order: order,
        filters: this.filters,
        includes: ["subscription", "subscription.billing"],
      };

      this.$store
        .dispatch("locations/list", params)
        .then((p) => {
          if (p && p.items) {
            this.locations = p.items;
            this.totalCount = p.count;
          } else {
            this.locations = [];
          }
        })
        .catch((err) => {
          this.error = err;
          this.locations = [];
        })
        .finally(() => (this.loading = false));
    },
    download() {
      if (this.loading) return;

      const url = new URL("/api/v1/locations", document.baseURI);
      if (this.filters) {
        Object.keys(this.filters).forEach((k) => {
          if (Array.isArray(this.filters[k]))
            this.filters[k].forEach((v) => url.searchParams.append(k, v));
          else url.searchParams.append(k, params.filters[k]);
        });
      }

      if (this.from) {
        url.searchParams.append("from", this.from);
      }

      if (this.to) {
        url.searchParams.append("to", this.to);
      }

      this.loading = true;
      this.error = null;
      this.$http
        .get(url.toString(), {
          headers: {
            Accept: "text/csv",
          },
        })
        .then((r) => {
          let blob = new Blob([r.data], { type: r.headers["content-type"] });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "maximi_locations.csv";
          link.click();
        })
        .catch((err) => (this.error = err))
        .finally(() => (this.loading = false));
    },
    getFilters() {
      if (window.sessionStorage) {
        let filter = window.sessionStorage.getItem("LocationListView.Filter");
        if (filter) {
          return JSON.parse(filter);
        }
      }

      return {
        status: ["ACTIVE"],
        visibility: ["Published"],
      };
    },
    storeFilters() {
      if (window.sessionStorage) {
        if (Object.keys(this.filters).length > 0) {
          window.sessionStorage.setItem(
            "LocationListView.Filter",
            JSON.stringify(this.filters)
          );
        } else {
          window.sessionStorage.removeItem("LocationListView.Filter");
        }
      }
    },
  },
  components: {
    LocationFilter,
  },
  computed: {
    _formattedFrom() {
      return this.from ? this.$d(new Date(this.from), "short") : "";
    },
    _formattedTo() {
      return this.to ? this.$d(new Date(this.to), "short") : "";
    },
  },
};
</script>

<style scoped>
.date-field {
  width: 100px;
}
</style>
