<template>
  <v-container>
    <app-error v-if="error" :error="error" />
    <app-loader v-if="!location" large></app-loader>
    <div v-else>
      <v-row>
        <v-col cols="9" md="11">
          <h1>{{ location.title }}</h1>
        </v-col>
        <v-col cols="3" md="1">
          <h6>
            <v-chip>{{ location.status }}</v-chip>
          </h6>
        </v-col>
      </v-row>
      <v-divider class="mb-5" />
      <v-row>
        <v-col cols="12" md="6">
          <subscription-card :subscription="location.subscription" />
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="mb-3">
            <v-card-text>
              <organization-selection
                :organization="location.organization"
                @change="organizationChanged"
              />
            </v-card-text>
          </v-card>

          <contact-card
            :contact="location.subscription.billing"
            :title="$t('contacts.sources.INVOICING')"
          />
          <contact-card
            :contact="location.contact"
            :title="$t('locations.contact')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <analytics-card
            :subject="location"
            :url="'/api/v1/locations/' + uid + '/analytics'"
          ></analytics-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import SubscriptionCard from "@/components/subscriptions/SubscriptionCard";
import ContactCard from "@/components/contacts/ContactCard";
import OrganizationSelection from "@/components/organizations/OrganizationSelection";
import AnalyticsCard from "@/components/analytics/AnalyticsCard";

export default {
  props: {
    uid: { type: String, required: true },
  },
  data() {
    return {
      location: null,
      error: null,
    };
  },
  created() {
    this.$store
      .dispatch("locations/get", {
        uid: this.uid,
        includes: ["contact", "subscription", "subscription.billing"],
      })
      .then((l) => (this.location = l))
      .catch((err) => {
        this.error = err;
      });
  },
  components: {
    AnalyticsCard,
    SubscriptionCard,
    ContactCard,
    OrganizationSelection,
  },
  methods: {
    organizationChanged(organization) {
      if (organization) {
        this.$store.dispatch("locations/setOrganization", {
          uid: this.location.uid,
          organization,
        });
      } else {
        this.$store.dispatch("locations/removeOrganization", this.location.uid);
      }
    },
  },
};
</script>

<style></style>
